<template>
  <div>
  <div style="text-align: center">

    <h1>年度信息</h1>

    <el-button icon="el-icon-plus" size="small" type="success" @click="ifAdd=true">新增年度</el-button>
    <el-button icon="el-icon-refresh"  size="small" type="danger" @click="ifUpdate=true">调整当前年度</el-button>
<!--    <el-button icon="el-icon-refresh"  size="small" type="danger" @click="ifUpdate=true">删除当前年度</el-button>-->
    <br>
    <br>
    <br>
    <div style="text-align: center">
      <el-table
        v-loading="loading"
        element-loading-text="加载中"
        :data="tableData"
        style="width: 100% "
        :default-sort="{prop: 'id', order: 'descending'}"
        :row-class-name="tableRowClassName">
        <el-table-column
          prop="id"
          label="年度ID"
          width="100">
        </el-table-column>
        <el-table-column
          prop="year"
          label="年度"
          width="200">
        </el-table-column>
        <el-table-column
          prop="startDate"
          label="开始日期"
        >
        </el-table-column>
        <el-table-column
          prop="endDate"
          label="结束日期">
        </el-table-column>
      </el-table>
    </div>


  </div>

  <el-dialog
    title="添加年度"
    align="center"
    :visible.sync="ifAdd"
    width="40%">
    <div>
    <div class="block">
      <el-date-picker
        v-model="newDate"
        type="daterange"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
      <div>
        <br>
        <br>
        <br>
        <el-button size="small" @click="ifAdd=false">取消添加</el-button>
        <el-button size="small" type="primary" @click="beforeAdd">确定添加</el-button>

      </div>

    </div>
  </el-dialog>

  <el-dialog
    title="修改当前年度时间"
    align="center"
    :visible.sync="ifUpdate"
    width="30%">
    <div>

      <div class="block">
        <h3 class="demonstration">重新设置当年年度开始、结束日期 </h3>

        <el-date-picker
          v-model="editDate"
          type="daterange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
        <br>
        <br>
        <br>
      </div>


      <div>
        <el-button size="small" @click="ifUpdate=false">取消修改</el-button>

        <el-button size="small" type="primary" @click="handleConfirmUpdate">确定修改</el-button>
      </div>
    </div>
  </el-dialog>


  </div>


</template>

<script>
import { request } from '@/network/network'


export default {
  name: 'YearA',
  data() {
    return {
      loading: true,
      newDate: [],
      thisYear:'',

      ifAdd: false,
      ifUpdate: false,
      tableData: [],
      currentYear: { 'startDate': '' },
      editDate: [],
      adminInformation: JSON.parse(sessionStorage.getItem("information"))

    }

  },
  methods: {
    //第一行特殊颜色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 0) {
        return 'warning-row'
      } else {
        return ''
      }

    },
    //点击添加年度按钮
    handleAdd() {
      this.ifAdd = true

    },
    beforeAdd(){
      let addYear = this.newDate[0].substring(0,4) + '年度'
      for (const item of this.tableData) {
        console.log(item)
        if(addYear == item.year ){

          this.$message({
            message: addYear+'已经存在！',
            type: 'error',
            duration: 1000
          })
          return false
        }
      }
      this.handleConfirmAdd()
    },
    //点击确定添加年度按钮
    handleConfirmAdd() {
      let year = { 'startDate': this.newDate[0], 'endDate': this.newDate[1] }
      console.log(year)
      this.addYear(year)
        .then((res) => {
          this.getYears()
          this.$message({
            message: '新增年度成功！',
            type: 'success',
            duration: 1000
          })
          this.newDate = []
          this.ifAdd = false
        })
        .catch((err) => {
          this.getYears()
          this.$message({
            message: '发生错误，新增年度失败！',
            type: 'error',
            duration: 1000
          })
          this.ifAdd = false
        })
    },
    handleConfirmUpdate() {

      let year = { 'id': this.thisYear, 'startDate': this.editDate[0], 'endDate': this.editDate[1] }
      console.log(year)
      this.updateYear(year)
        .then((res) => {
          this.getYears()
          this.$message({
            message: '修改成功！',
            type: 'success',
            duration: 1000
          })
          this.ifUpdate = false
        })
        .catch((err) => {
          this.getYears()
          this.$message({
            message: '发生错误，修改年度失败！',
            type: 'error',
            duration: 1000
          })
          this.ifUpdate = false
        })

    },
    getYears() {
      return request({
        url: '/year/years',
        method: 'get',
        params: {}
      }).then(res => {
        let current = 0;
        let temp = res.data
        for (let year of temp) {
          if(year.id > current){
            current = year.id
          }
        }
        this.thisYear = current
        this.tableData = temp
          .map(item => {
            item.year = (item.startDate.substring(0,4)) + '年度'
          // item.startDate = formatDate(item.startDate)
          // item.endDate = formatDate(item.endDate)
          // item.createTime = formatDate(item.createTime)
          // item.updateTime = formatDate(item.updateTime)
          return item
        })
        this.loading = false
      })
    },
    addYear(year) {
      return request({
        url: '/year/addYear',
        method: 'post',
        data: year
      })
    },
    updateYear(year) {
      return request({
        url: '/year/updateYear',
        method: 'post',
        data: year
      })
    }
  },
  created() {
    this.getYears()

  }
}
</script>

<style >
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

</style>
